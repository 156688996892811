import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//auth
import $ from 'jquery'
import { HTTP } from "@/axios";

const axios = require('axios');


function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  var string = localStorage.getItem("token");

  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (string != null) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/"); // go to '/login';
  }
}

function guardHome(to, from, next) {
  var isAuthenticated = false;
  var string = localStorage.getItem("token");
  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (string != null ) isAuthenticated = true;
  else isAuthenticated = false;

  if (isAuthenticated) {
    next("/dashboard"); // allow to enter route
  } else {
    next(); // go to '/login';
  }
}


//permission-routes
function testPermission(to, from, next) {
  if(window.localStorage.getItem("userData")){
  var valide = false;
  let newObject = JSON.parse(window.localStorage.getItem("userData"));
  let permissions=[];
  permissions.push(newObject[0].role_responsable.permissions)
  const isFound = permissions[0].some(element => {
    if (element.titre === to.meta || to.name=="dashboard" || to.name=="encours" ) {
      valide= true;
    }
  });
              
  if (valide) {
    next(); 
  } else {
    next("/error-404"); 
  }


}




}


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    //login
    {
      path: "/",
      name: "login",
      beforeEnter: guardHome,
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "home",
      },
    },

    //home
    {
      path: "",
      component: () => import("@/layouts/layout.vue"),
     beforeEnter: guardMyroute,

      children: [
        //dashboard
        {
          path: "/dashboard",
          name: "dashboard",
          beforeEnter: testPermission,
          component: () => import("@/views/Home.vue"),
        },

        //objectifs
        {
          path: "/objectifs",
          name: "objectifs",
          beforeEnter: testPermission,

          meta:"gestion des objectifs",
          component: () => import("@/views/objectifs/objectifs.vue"),
        },
        {
          path: "/addobjectifs",
          name: "addobjectifs",
          meta:"gestion des objectifs",
          beforeEnter: testPermission,

          component: () => import("@/views/objectifs/addobjectifs.vue"),
        },
        {
          path: "/modifobjectifs/:id",
          name: "modifobjectifs",
          meta:"gestion des objectifs",
          beforeEnter: testPermission,

          component: () => import("@/views/objectifs/modifobjectifs.vue"),
        },
        //produits
        {
          path: "/produits",
          name: "produits",
          meta:"gestion des articles",
          beforeEnter: testPermission,


          component: () => import("@/views/produits/index.vue"),
        },
     
        {
          path: "/ajouterproduit",
          name: "ajouter",
          meta:"gestion des articles",

          beforeEnter: testPermission,


          component: () => import("@/views/produits/ajouter.vue"),
        },
        {
          path: "/modifierproduit/:id",
          name: "modifier",
          meta:"gestion des articles",

          beforeEnter: testPermission,


          component: () => import("@/views/produits/modifier.vue"),
        },
        //zones
        {
          path: "/getzones",
          name: "zones",
          meta:"gestion des zones",
          beforeEnter: testPermission,

          component: () => import("@/views/zones/index"),
        },
        {

          path: '/showzones',
          name: 'zones',

          component: () => import('@/views/zones/show')
        },

        {
          path: '/addzone',
          name: 'zones',
          meta:"gestion des zones",
          beforeEnter: testPermission,

          component: () => import('@/views/zones/add'),

        },
        {
          path: "/editzone/:id", // the URL accepts an accountId parameter
          name: "updatezone",
          meta:"gestion des zones",
          beforeEnter: testPermission,

          component: () => import("@/views/zones/edit"),
        },
        //clients
        {
          path: "/getclients",
          name: "clients",
          meta:"gestion des clients",
          beforeEnter: testPermission,

         component: () => import("@/views/clients/index"),
        },
        {
          path: "/addclient",
          name: "clients",
          meta:"gestion des clients",
          beforeEnter: testPermission,

          component: () => import("@/views/clients/add"),
        },
        {
          path: "/editclient/:id", // the URL accepts an accountId parameter
          name: "updateclient",
          meta:"gestion des clients",
          beforeEnter: testPermission,

          component: () => import("@/views/clients/edit"),
        },

        //emplacements
        {
          path: "/emplacements",
          name: "emplacements",
          meta:"gestion des emplacements",
          beforeEnter: testPermission,

          component: () => import("@/views/emplacements/index"),
        },
        {
          path: "/addemplacements",
          name: "addemplacements",
          meta:"gestion des emplacements",
          beforeEnter: testPermission,

          component: () => import("@/views/emplacements/addemplacement"),
        },
        {
          path: "/editemplacements/:id", // the URL accepts an accountId parameter
          name: "editemplacements",
          meta:"gestion des emplacements",
          beforeEnter: testPermission,

          component: () => import("@/views/emplacements/updatemplacement"),
        },

        //stocks
        {
          path: "/stocks",
          name: "stocks",
          meta:"gestion des articles/stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/index"),
        },
        {
          path: "/addstocks",
          name: "addstocks",
          meta:"gestion des articles/stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/add"),
        },
        {
          path: "/updatestocks/:id", // the URL accepts an accountId parameter
          name: "updatestocks",
          meta:"gestion des articles/stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/update"),
        },

        {
          path: "/stocks",
          name: "stocks",
          meta:"gestion des articles/stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/index"),
        },

          {
          path: "/stocks",
          name: "stocks",
          meta:"gestion des articles/stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/index"),
        },
        //visites
        {
          path: "/visites",
          name: "visites",
          meta:"gestion des visites",
          beforeEnter: testPermission,

          component: () => import("@/views/visites/index"),
        },

        {
          path: "/addvisite",
          name: "visites",
          meta:"gestion des visites",
          beforeEnter: testPermission,

          component: () => import("@/views/visites/add"),
        },
        {
          path: "/editvisite/:id", // the URL accepts an accountId parameter
          name: "updatevisite",
          meta:"gestion des visites",
          beforeEnter: testPermission,

          component: () => import("@/views/visites/edit"),
        },
        {
          path: "/calendrier", // the URL accepts an accountId parameter
          name: "calendrier",
          meta:"gestion des visites",
          beforeEnter: testPermission,

          component: () => import("@/views/visites/calendrier"),
        },
//formations 
        {
          path: "/formations",
          name: "formations",
          meta:"gestion des formations",
          beforeEnter: testPermission,

          component: () => import("@/views/formations/index"),
        },

        {
          path: "/addformation",
          name: "formations",
          meta:"gestion des formations",
          beforeEnter: testPermission,

          component: () => import("@/views/formations/add"),
        },

        {
          path: "/updateformation/:id",
          name: "updateformation",

          meta:"gestion des formations",


          component: () => import("@/views/formations/update"),
        },



        //marketings 
        {
          path: "/marketings",
          name: "marketings",
          meta:"gestion des marketings",
          
          component: () => import("@/views/marketings/index"),
        },

        {
          path: "/addmarketing",
          name: "marketings",
          meta:"gestion des marketings",
          beforeEnter: testPermission,

          component: () => import("@/views/marketings/add"),
        },
        {
          path: "/calendrieranimatrice",
          name: "marketings",
          meta:"gestion des marketings",

          component: () => import("@/views/marketings/calendrier"),
        },
        {
          path: "/updatemarketing/:id",
          name: "updatemarketing",

          component: () => import("@/views/marketings/update"),
        },


         //annonce 
         {
          path: "/annonces",
          name: "annonces",
          meta:"gestion des annonces",

          component: () => import("@/views/annonces/index"),
        },

        {
          path: "/addannonce",
          name: "addannonces",
          meta:"gestion des annonces",

          component: () => import("@/views/annonces/add"),
        },
       
        {
          path: "/updateannonce/:id",
          name: "updateannonce",
          meta:"gestion des annonces",

          component: () => import("@/views/annonces/update"),
        },

          //audit 
          {
            path: "/audit",
            name: "audits",
            meta:"gestion des audits",

            component: () => import("@/views/audits/index"),
          },

          {
            path: "/addaudit",
            name: "addaudit",
            meta:"gestion des audits",

            component: () => import("@/views/audits/add"),
          },

          {
            path: "/updateaudit/:id",
            name: "updateaudit",
            meta:"gestion des audits",

            component: () => import("@/views/audits/update"),
          },
        //utilisateurs 
        {
          path: "/adduser",
          name: "users",
          meta:"gestion des utilisateurs",

          component: () => import("@/views/users/add"),
        },
       
        {
          path: "/users",
          name: "users",
          meta:"gestion des utilisateurs",
          beforeEnter: testPermission,

          component: () => import("@/views/users/index"),
        },

      
     //groupes
         {
          path: "/addgroup",
          name: "groups",
          meta:"gestion des groupes",
          beforeEnter: testPermission,

          component: () => import("@/views/groups/add"),
        },
       
        {
          path: "/getgroups",
          name: "groups",
          meta:"gestion des groupes",
          beforeEnter: testPermission,

          component: () => import("@/views/groups/index"),
        },
        {
          path: "/updategroups/:id",
          name: "updategroups",
          meta:"gestion des groupes",

          component: () => import("@/views/groups/update"),
        },
//commandes
        {
          path: "/commandes",
          name: "commandes",
          meta:"gestion des commandes",
          beforeEnter: testPermission,

          component: () => import("@/views/commandes/commandes")
        },

        {
          path: "/addcommandes",
          name: "addcommandes",
          meta:"gestion des commandes",
          beforeEnter: testPermission,

          component: () => import("@/views/commandes/addcommandes")
        },


        {
          path: "/showcommandes/:id",
          name: "showcommandes",
          meta:"gestion des commandes",
          beforeEnter: testPermission,

          component: () => import("@/views/commandes/showcommandes")
        },



//commandeStock
        {
          path: "/demandecommandes",
          name: "demandecommandes",
          meta:"gestion des demande_stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/demandecommandes")
        },
        {
          path: "/showdemandescommandes/:id",
          name: "showdemandescommandes",
          meta:"gestion des demande_stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/showcommandes")
        },
        {
          path: "/showechantillondemmande/:id",
          name: "showechantillondemmande",
          meta:"gestion des demande_stocks",
          beforeEnter: testPermission,

          component: () => import("@/views/stocks/showechantillon")
        },
 //roles

        {
          path: "/addrole",
          name: "addrole",
          meta:"gestion des roles",
          beforeEnter: testPermission,

          component: () => import("@/views/roles/add")
        },
        {
          path: "/getrole",
          name: "getrole",
          meta:"gestion des roles",
          beforeEnter: testPermission,

          component: () => import("@/views/roles/roles")
        },
        {
          path: "/editrole/:id", // the URL accepts an accountId parameter
          name: "editrole",
          meta:"gestion des roles",
          beforeEnter: testPermission,

          component: () => import("@/views/roles/edit"),
        },
//echantillon
        {
          path: "/demandeechantillon", 
          name: "demandeechantillon",
          meta:"gestion des demandes_echantillon",
          beforeEnter: testPermission,

          component: () => import("@/views/echantillons/demande_echantillon"),
        },


   //showdemandeechantillon
        {
          path: "/showdemande/:id", 
          name: "showdemande",
          meta:"gestion des demandes_echantillon",
          beforeEnter: testPermission,

          component: () => import("@/views/echantillons/showdemande"),
        },

   //addechantillon
          {
            path: "/addechantillon", 
            name: "addechantillon",
            meta:"gestion des demandes_echantillon",
          beforeEnter: testPermission,
            
            component: () => import("@/views/echantillons/add_echantillon"),
          },



    //magasins  
    {
      path: "/getmagasins", 
      name: "getmagasins",
      meta:"gestion des magasins",
      beforeEnter: testPermission,

      component: () => import("@/views/magasins/index"),
    },    
    {
      path: "/addmagasin", 
      name: "addmagasin",
      meta:"gestion des magasins",
      beforeEnter: testPermission,

      component: () => import("@/views/magasins/add"),
    },

    {
      path: "/updatemagasin/:id", 
      name: "addmagasin",
      meta:"gestion des magasins",
      beforeEnter: testPermission,

      component: () => import("@/views/magasins/update"),
    },


        //unités  
        {
          beforeEnter: testPermission,

          path: "/getunites", 
          name: "encours",
          component: () => import("@/views/unites/index"),
        },  




          //profildelegue  
          {
            
            path: "/profildelegue", 
            name: "profildelegue",
            component: () => import("@/views/delegue/profildelegue"),
          },  
  







    //neww

    {
      path: "/encours", 
      name: "encours",
      meta:"gestion des encours",
      beforeEnter: testPermission,

      component: () => import("@/views/encours"),
    },

    {
      path: "/encours1", 
      name: "encours",
      meta:"gestion des encours",
      beforeEnter: testPermission,

      component: () => import("@/views/encours"),
    },
    {
      path: "/encours2", 
      name: "encours",
      meta:"gestion des encours",
      beforeEnter: testPermission,

      component: () => import("@/views/encours"),
    },
    {
      path: "/encours3", 
      name: "encours",
      meta:"gestion des encours",
      beforeEnter: testPermission,

      component: () => import("@/views/encours"),
    },













    
      ],
    },

    //erroor
    {
      path: "/error-404",
      
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
  linkActiveClass: "active",
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;