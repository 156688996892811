import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";

import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';

Vue.use(SchedulePlugin);
import 'animate.css';
import { HTTP } from '@/axios';
Vue.use(HTTP)

///map//////AIzaSyBwtBfMps7h3v1d9olsHmFpKnH1LKGc8t4


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAw1r8H3n2HqgMpLOK18_QOiZD-we3lGhA',
    libraries: 'places', 
    
  },
 

})

///////////

import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import { Icon } from "leaflet";



Vue.prototype.moment = moment;

import moment from 'moment';
Vue.use(moment)

import $ from 'jquery'
Vue.use($)



Vue.prototype.moment = moment
Vue.filter('formatDate', function(value) {

  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter('formatDateTime', function(value) {

  if (value) {
    return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
  }
});




Vue.config.productionTip = false;

Vue.use(Vuetify);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';


Vue.use(Vuetify)
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
window.Swal = Swal;
window.Toast = Toast;


//require png
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});




//axios
import Axios from 'axios';
Axios.defaults.baseURL = process.env.API_ENDPOINT;

//clear local storage

window.onload = () => {
  // Clear localStorage
  if (localStorage.getItem('token') === 'true') {
    
    localStorage.removeItem('token');
    // Show them the sign in form
  }
};
console.log(localStorage.token)

window.onbeforeunload = function (e) {
  window.onunload = function () {
          window.localStorage.session = "false";
  }
  return undefined;
};

window.onload = function () {
          window.localStorage.session = "true";
};


Vue.component("modal",{
	props:['name'],
	template:`
		<div class="modal fade in modal-active">
			<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<button type="button" @click="$emit('close')" class="close"><span >&times;</span></button>
							<h4 class="modal-title">
								{{name}}
							</h4>
						</div>
						<div class="modal-body">
								<slot></slot>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default" @click="$emit('close')">Close</button>
							<button type="button" class="btn btn-primary">Save changes</button>
						</div>
				</div>
			</div>
		</div>`
})

import VModal from 'vue-js-modal'
Vue.use(VModal)


new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");